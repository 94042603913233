document.addEventListener('turbolinks:load', () => {
  let formDefined = document.getElementById("new_organization_form");
  if(formDefined){
    NewOrganizationForm.showOrHideFieldOtherTitle();
    NewOrganizationForm.addEventToPositionField();
  }
})

NewOrganizationForm = {
  addEventToPositionField: function(){
    $("#select_title").on("change", (event) => {
      this.showOrHideFieldOtherTitle();
    });
  },
  showOrHideFieldOtherTitle: function(){
    let selected = $("#select_title").val();
    if(selected != 'Other'){
      $("#div_other_title").hide();
      $("#other_title_text").val(selected);
    }else{
      $("#div_other_title").show();
    }
  }
}
